import React, { useEffect } from "react";
import { getAllTemplates } from "../../data/slices/templates";
import { useDispatch, useSelector } from "react-redux";
import LoaderComponent from "../../components/LoaderComponent/LoaderComponent";

function LtiDesktopLauncher() {
  const dispatch = useDispatch();

  const { allTemplates } = useSelector((state) => state.templates);
  console.log(allTemplates, "allTemplates");
  useEffect(() => {
    // if (allTemplates?.length === 0 || !allTemplates) {
    //   dispatch(getAllTemplates({ query: "" }));
    // }
  }, []);
  return (
    <div>
      <LoaderComponent />
    </div>
  );
}

export default LtiDesktopLauncher;
