import React, { useEffect } from "react";
import LoaderComponent from "../../components/LoaderComponent/LoaderComponent";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../data/slices/authSlice";
import { getAllTemplates } from "../../data/slices/templates";
import { new_session, newSession } from "../../data/slices/sessions";
import { message } from "antd";

function LtiAuthentication() {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isAuthenticated } = useSelector((state) => state.auth);

  let values = {
    state: "test1234",
    username: "marwen",
    password: "12345678"
  };

  const createSession = (desktop) => {
    dispatch(
      newSession({
        namespace: "default",
        template: desktop.name,
        sessionConfig: {
          cpu: desktop?.skuConfig?.cpu?.defaultValue,
          ram: desktop?.skuConfig?.ram?.defaultValue,
          storage: desktop?.skuConfig?.storage?.defaultValue
        },
        shouldPersist: false,
        redirectURL: ""
      })
    )
      .unwrap()
      .then((res) => {
        if (res) {
          dispatch(new_session(res));
          navigate(`/control/${res.name}`, {
            state: res
          });
        }
      })
      .catch((err) => {
        message.error(err.message);
        navigate("/admin/plans");
      });
  };

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getAllTemplates({ query: "" }))
        .unwrap()
        .then((res) => {
          const targetDesktop = res?.templates?.find(
            (desktop) => desktop?.name === params?.desktop_id
          );
          if (targetDesktop) {
            createSession(targetDesktop);
          } else {
            message.error("Desktop not found");
          }
        });
    } else {
      dispatch(login({ state: "test1234", ...values }))
        .unwrap()
        .then(() => {
          dispatch(getAllTemplates({ query: "" }))
            .unwrap()
            .then((res) => {
              const targetDesktop = res?.templates?.find(
                (desktop) => desktop?.name === params?.desktop_id
              );
              if (targetDesktop) {
                createSession(targetDesktop);
              } else {
                message.error("Desktop not found");
              }
            });
        });
    }
  }, []);

  return (
    <div className="lti-auth">
      <div className="lti-loading">
        <LoaderComponent />
      </div>
    </div>
  );
}

export default LtiAuthentication;
